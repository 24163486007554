import "./src/styles/style.css";
import { navigate } from "gatsby";
import { datadogRum } from "@datadog/browser-rum";
import React from "react";
import RootElement from "./src/components/root-element";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource-variable/inter";
import "@fontsource-variable/montserrat";
import "@fontsource-variable/source-sans-3";

declare global {
  interface Window {
    gtmScriptAdded?: boolean;
    osanoScriptAdded?: boolean;
  }
}

datadogRum.init({
  applicationId: process.env.GATSBY_DATADOG_RUM_APPLICATION_ID || "",
  clientToken: process.env.GATSBY_DATADOG_RUM_CLIENT_TOKEN || "",
  site: process.env.GATSBY_DATADOG_RUM_SITE,
  service: process.env.GATSBY_DATADOG_RUM_ENV + "-corp-website-rum",
  env: process.env.GATSBY_DATADOG_RUM_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};

export const onClientEntry = () => {
  if (!window.osanoScriptAdded) {
    const script = document.createElement("script");
    script.src =
      "https://cmp.osano.com/169xuqUPUjoZK3hiz/70aba732-bc5d-4f8e-b4de-e7e033ff0a61/osano.js";
    script.async = true;
    document.head.appendChild(script);
    window.osanoScriptAdded = true;
  }
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  const pathParts = location.pathname.split("/");
  const currentLocale = pathParts[1];
  const supportedLocales = ["es", "fr", "de", "nl", "zh"];
  if (supportedLocales.includes(currentLocale)) {
    // If the current path already includes a supported locale, do nothing
    return;
  } else {
    if (prevLocation) {
      const prevPathParts = prevLocation.pathname.split("/");
      const prevLocale = prevPathParts[1].split("-")[0];
      if (supportedLocales.includes(prevLocale)) {
        const newPath = `/${prevLocale}${location.pathname}`;
        return navigate(newPath, { replace: true });
      }
    }
  }
};

export const onRouteUpdate = ({ location }: { location: Location }) => {
  // New test GTM container for culture page
  const GTM_ID_TEST = "GTM-K872NKBR";
  if (process.env.REACT_APP_ENVIRONMENT == "production") {
    if (location.pathname.includes("/culture")) {
      if (!window.gtmScriptAdded) {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          const f = d.getElementsByTagName(s)[0],
            j: any = d.createElement(s),
            dl = l !== "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode!.insertBefore(j, f);
        })(window, document, "script", "dataLayer", GTM_ID_TEST);
        // Set a flag to avoid adding the script on every routeUpdate
        window.gtmScriptAdded = true;
      }
    }
  }
};
